import * as React from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import logo from '../images/logo-gtr-gen.png'
import { Link } from 'gatsby'
import styled from '@emotion/styled'

const NavbarToggle = styled(Navbar.Toggle)`
  border: none;
`

const BrandLink = styled(Link)`
  display: flex;
  align-items: center;
`

const CallToActionLink = styled(Link)`
  margin-right: 1rem;
  border: 1px solid #3182ce;
  
  @media screen and (max-width: 1240px) {
    margin-bottom: 5px;
	margin-left: 0!important;
	margin-right: 0!important;
  }
`

export const Header = (props: { location: Location }) => {
  return (
    <>
      <Navbar collapseOnSelect bg="light" expand="md">
        <BrandLink className="nav-link navbar-brand" to="/">
          <img alt="" src={logo} width="170" height="20" className="d-inline-block align-top mr-3" />
        </BrandLink>
        <NavbarToggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <span className="mr-auto" />
          <Nav>
            {props.location?.pathname !== '/' && (
              <CallToActionLink className="btn btn-dark cta mr-0 mx-3" to="/">
                ← Редактор диаграмм
              </CallToActionLink>
            )}

            <Link className="nav-link" to="/help">
              FAQ
            </Link>
            <Link className="nav-link" to="/about">
              О сервисе
            </Link>
			<Link className="btn btn-red cta mr-0 mx-3" to="https://samesound.ru" target="_blank">
              На SAMESOUND →
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  )
}

export default Header
