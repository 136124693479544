import React from 'react';
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import { Vk, Telegram } from 'styled-icons/simple-icons'

export default function App() {
  return (
    <MDBFooter className='text-center text-lg-start text-muted'>
      <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
        <div className='me-5 d-lg-block start'>
          <a href='https://vk.com/samesound' className='me-4 text-reset vk' target='_blank'>
            <Vk width={24} height={24} />
          </a>
		  <a href='https://t.me/samesound' className='me-4 text-reset telegram' target='_blank'>
            <Telegram width={24} height={24} />
          </a>
        </div>

        <div className='me-5 d-lg-block end'>
          <a href='/help' className='me-4 text-white start'>
            FAQ
          </a>
          <a href='/about' className='me-4 text-white end'>
            О проекте
          </a>
        </div>
      </section>

      <div className='text-center p-4 copyright' style={{ backgroundColor: '#E2E8F0' }}>
        &copy; 2022-{new Date().getFullYear()} {' '}
        <a className='text-white copyright' href='https://samesound.ru' target='_blank'>
          SAMESOUND
        </a>
      </div>
    </MDBFooter>
  );
}