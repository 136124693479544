import styled from '@emotion/styled'
import React, { useMemo } from 'react'
import useMedia from 'use-media'
import useScript from 'hooks/useScript';

interface ReferralsProps {}

const desktopReferrals: React.ReactNode[] = [
<div>
	<div id="yandex_rtb_R-A-1795679-1"></div>	
	<script
		dangerouslySetInnerHTML={{
		__html:`window.yaContextCb.push(()=>{
		Ya.Context.AdvManager.render({
		renderTo: 'yandex_rtb_R-A-1795679-1',
		blockId: 'R-A-1795679-1'
		})
		})
`,
}}
/>
	</div>
]

const mobileReferrals: React.ReactNode[] = [
  <div>
 	<div id="yandex_rtb_R-A-1795679-2"></div>	
	<script
		dangerouslySetInnerHTML={{
		__html: `
		window.yaContextCb.push(()=>{
		Ya.Context.AdvManager.render({
		renderTo: 'yandex_rtb_R-A-1795679-2',
		blockId: 'R-A-1795679-2'
		})
		})
`,
}}
/>   
  </div>
]

const sample: <T>(arr: T[]) => T = (arr) => arr[Math.floor(Math.random() * arr.length)]

const StyledDiv = styled.div`
  margin-block-start: 2rem;
  display: flex;
  justify-content: center;
`

export const Referrals: React.FunctionComponent<ReferralsProps> = ({}) => {
  const isDesktop = useMedia({ minWidth: '786px' })

  const component = useMemo(() => (isDesktop ? sample(desktopReferrals) : sample(mobileReferrals)), [isDesktop])

  return <StyledDiv>{component}</StyledDiv>
}
