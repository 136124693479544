import * as React from 'react'
import Helmet from 'react-helmet'
import { graphql, StaticQuery } from 'gatsby'

import 'modern-normalize'
import Header from '../components/Header'
import LayoutRoot from '../components/LayoutRoot'
import LayoutMain from '../components/LayoutMain'
import { Referrals } from '../components/referrals/Referrals'
import Footer from '../components/Footer'

interface StaticQueryProps {
  site: {
    siteMetadata: {
      title: string
      description: string
      keywords: string
    }
  }
}

const IndexLayout: React.FC<{ location: Location }> = ({ children, location }) => (
  <StaticQuery
    query={graphql`
      query IndexLayoutQuery {
        site {
          siteMetadata {
            title
            description
            keywords
          }
        }
      }
    `}
    render={(data: StaticQueryProps) => (
      <LayoutRoot>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'keywords', content: data.site.siteMetadata.keywords },
            { name: 'description', content: data.site.siteMetadata.description }	
          ]}
        >
          <html lang="ru" />
		  <link rel="preconnect dns-prefetch" href="https://mc.yandex.ru" />
		  <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
		  <link href="https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,400;0,500;0,600;0,700;1,400;1,600;1,700&display=swap" rel="stylesheet" />
		  <link href="https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:wght@500;600;700&display=swap" rel="stylesheet" />
		  <link rel="canonical" href="https://guitardiagrams.samesound.ru/" data-baseprotocol="https:" data-basehost="guitardiagrams.samesound.ru" />
		  <link rel="icon" href="/favicon-32x32.png" type="image/png" />
		  <link rel="manifest" href="/manifest.webmanifest" crossorigin="anonymous" />
		  <meta name="theme-color" content="#000" />
		  <link rel="apple-touch-icon" sizes="48x48" href="/icons/icon-48x48.png" />
		  <link rel="apple-touch-icon" sizes="72x72" href="/icons/icon-72x72.png" />
		  <link rel="apple-touch-icon" sizes="96x96" href="/icons/icon-96x96.png" />
		  <link rel="apple-touch-icon" sizes="144x144" href="/icons/icon-144x144.png" />
		  <link rel="apple-touch-icon" sizes="192x192" href="/icons/icon-192x192.png" />
		  <link rel="apple-touch-icon" sizes="256x256" href="/icons/icon-256x256.png" />
		  <link rel="apple-touch-icon" sizes="384x384" href="/icons/icon-384x384.png" />
		  <link rel="apple-touch-icon" sizes="512x512" href="/icons/icon-512x512.png" />
		  <link rel="sitemap" type="application/xml" href="/sitemap.xml" />
		  <meta property="og:type" content="article" />
		  <meta property="og:url" content="https://guitardiagrams.samesound.ru" />
		  <meta property="og:title" content="Генератор диаграмм гитарных аккордов SAMESOUND" />
		  <meta property="og:description" content="Сервис для создания диаграмм гитарных аккордов и схем аккордов струнных инструментов." />
		  <meta property="og:image" content="https://guitardiagrams.samesound.ru/og-img.jpg" />
		  <meta property="og:image:type" content="image/jpeg" />
		  <meta property="og:image:width" content="1200" />
		  <meta property="og:image:height" content="600" />
		  <meta property="og:locale" content="ru_RU" />
		  <meta property="og:site_name" content="SAMESOUND" />
		  <meta property="article:publisher" content="https://facebook.com/samesoundmag" />		
		  <meta property="twitter:card" content="summary_large_image" />
		  <meta property="twitter:url" content="https://guitardiagrams.samesound.ru" />
		  <meta property="twitter:creator" content="@samesoundmag" />
		  <meta property="twitter:site" content="@samesoundmag" />
		  <meta property="twitter:title" content="Генератор диаграмм гитарных аккордов SAMESOUND" />
		  <meta property="twitter:description" content="Сервис для создания диаграмм гитарных аккордов и схем аккордов струнных инструментов." />
		  <meta property="twitter:image" content="https://guitardiagrams.samesound.ru/og-img.jpg" />		
		  <script>window.yaContextCb=window.yaContextCb||[]</script>
		  <script src="https://yandex.ru/ads/system/context.js" async></script>
		  <script defer data-domain="guitardiagrams.samesound.ru" src="https://stats.samesound.ru/js/script.js"></script>
        </Helmet>
        <Header location={location} />
        <LayoutMain>{children}</LayoutMain>
        <Referrals />
		<Footer />
      </LayoutRoot>
    )}
  />
)

export default IndexLayout
